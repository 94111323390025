import EstTeam from './EstTeam'

function EstAboutUs () {
  return (
    <>
      <EstTeam color='pink' />
    </>
  )
}

export default EstAboutUs
